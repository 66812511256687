import { Fragment, useEffect, useState } from "react";
import BodyContainer from "../../components/BodyContainer";
import TableComponent from "../../components/table/TableComponent";
import Modal from "../../components/Modal";
import AddEditAgentForm from "./components/addEditAgentForm";
import { useAgents } from "../../common/contexts/agentContext";
import styled from "styled-components";
import { COLOR_TEXT } from "../../constants/cts_colors";
import { IAgentInfos, IServiceInfos } from "../../interfaces/agent";
import {
  changeDateFormatDDMMYYYY,
  designBetweenTwoDate,
  getToday,
} from "../../utils/date";
import Button from "../../components/Button";
import { ITableData } from "../../interfaces/table";
import AddEditAgentBonusVacationsForm from "./components/addEditAgentBonusVacationsForm";
import { IBonifiedVacationInfos } from "../../interfaces/bonifiedVacation";
import AddEditAgentInactivitiesForm from "./components/addEditAgentInactivitiesForm";
import { IAgentInactivityInfos } from "../../interfaces/agentInactivity";
import { IFormationInfos } from "../../interfaces/formation";
import AddEditAgentFormationsForm from "./components/addEditAgentFormationsForm";
import { IDegreeInfos } from "../../interfaces/degree";
import AddEditAgentDegreesForm from "./components/addEditAgentDegreesForm";
import { IEmploymentInfos } from "../../interfaces/employment";
import AddEditAgentEmploymentsForm from "./components/addEditAgentEmploymentsForm";
import AddEditAgentFilesForm from "./components/addEditAgentFilesForm";
import ImportAgentForm from "./components/importAgentForm";
import AddEditAgentResponsibleForm from "./components/addEditAgentResponsibleForm";
import ToastAlert from "../../components/ToastAlert";
import AddEditAgentDivisionForm from "./components/addEditAgentDivisionForm";
import { ucFirst } from "../../utils/utils";
import { orderBy } from "lodash";
import AddEditFullAgentForm from "./components/addEditFullAgentForm";

const Agents = () => {
  const { onGetAllAgents, onDeleteAgent, onDeleteAllFromIdList } = useAgents();

  // toast
  const [toastVisible, _setToastVisible] = useState<boolean>(false);
  const [toastMessage, _setToastMessage] = useState<string>("");

  // DATA - VARIABLES
  const [data, _setData] = useState<ITableData | null>(null);
  const [triggerLoadDataFromDB, _setTriggerLoadDataFromDB] =
    useState<boolean>(false);
  const ROW_ACTIONS = {
    edit: "Modifier",
    employments: "Emplois",
    degrees: "Diplômes",
    formations: "Formations",
    inactivities: "Inactivités",
    bonusVacation: "Congés Bonifiés",
    archive: "Archiver",
  };
  // SELECT TABLE ROW - VARIABLES
  const [selectedRowsIds, _setSelectedRowsIds] = useState<Array<number>>([]);
  // ADD AGENT - VARIABLES
  const [isAddAgentModalOpen, _setIsAddAgentModalOpen] =
    useState<boolean>(false);
  // IMPORT AGENTS
  const [isImportAgentsModalOpen, _setIsImportAgentsModalOpen] =
    useState<boolean>(false);
  // EDIT AGENT - VARIABLES
  const [isEditAgentModalOpen, _setIsEditAgentModalOpen] =
    useState<boolean>(false);
  const [editFullFormData, _setEditFullFormData] = useState<IAgentInfos | null>(
    null
  );
  const [editFormData, _setEditFormData] = useState<IAgentInfos | null>(null);
  // EDIT FILES
  const [isEditFilesAgentModalOpen, _setIsEditFilesAgentModalOpen] =
    useState<boolean>(false);
  // EDIT TELEWORKING
  const [
    isEditResponsiblesAgentModalOpen,
    _setIsEditResponsiblesAgentModalOpen,
  ] = useState<boolean>(false);
  // ADD / EDIT DIVISION
  const [isAddEditAgentDivisionModalOpen, _setIsAddEditAgentDivisionModalOpen] =
    useState<boolean>(false);
  const [addEditAgentDivisionFormData, _setAddEditAgentDivisionFormData] =
    useState<IAgentInfos | null>(null);
  // EDIT INACTIVITIES
  const [
    isEditInactivitiesAgentModalOpen,
    _setIsEditInactivitiesAgentModalOpen,
  ] = useState<boolean>(false);
  const [editInactivitiesFormData, _setEditInactivitiesFormData] =
    useState<IAgentInactivityInfos | null>(null);
  // EDIT DEGREES
  const [editDegreesFormData, _setEditDegreesFormData] =
    useState<IDegreeInfos | null>(null);
  const [isEditDegreesAgentModalOpen, _setIsEditDegreesAgentModalOpen] =
    useState<boolean>(false);
  // EDIT FORMATIONS
  const [isEditFormationsAgentModalOpen, _setIsEditFormationsAgentModalOpen] =
    useState<boolean>(false);
  const [editFormationsFormData, _setEditFormationsFormData] =
    useState<IFormationInfos | null>(null);
  // EDIT EMPLOYMENTS
  const [isEditEmploymentsAgentModalOpen, _setIsEditEmploymentsAgentModalOpen] =
    useState<boolean>(false);
  const [editEmploymentsFormData, _setEditEmploymentsFormData] =
    useState<IEmploymentInfos | null>(null);
  // EDIT CONGE BONIFIES
  const [
    isEditBonusVacationsAgentModalOpen,
    _setIsEditBonusVacationsAgentModalOpen,
  ] = useState<boolean>(false);
  const [editCurrentAgentId, _setEditCurrentAgentId] = useState<number>();
  const [editBonifiedVacationsFormData, _setEditBonifiedVacationsFormData] =
    useState<IBonifiedVacationInfos | null>(null);
  // MODALS
  const [confirmModal, _setConfirmModal] = useState<{
    message: string;
    confirmedAction: Function;
    params?: Object;
  } | null>(null);

  // set title of the page
  useEffect(() => {
    document.title = "Agents | Sorbonne";
  }, []);

  // get and set the table data
  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = () => {
    onGetAllAgents()
      .then((returnData) => {
        if (returnData) {
          loadTableData(returnData);
        }
      })
      .catch((err) => console.error(err));
  };

  // format phone number with dot between 2 numbers
  const formatPhoneNumber = (phoneNumber: any) => {
    let numeros = (phoneNumber || "").replace(/\D/g, "");
    let numeroFormate = numeros.match(/.{1,2}/g)?.join(".");

    return numeroFormate || "";
  };

  // load table's data
  const loadTableData = (data: Array<IAgentInfos>) => {
    let rows: any = [];
    const columns: Array<string> = [
      "", // the select column
      "Matricule",
      "Nom",
      "Prénom",
      "Date de naissance",
      "Âge",
      "Genre",
      "Téléphone Professionnel",
      "Catégorie agent",
      "Corps agent",
      "Acronyme service DLM",
      "Designation service DLM",
      "Responsable du service",
      "Responsable adjoint du service",
      "Pôle/Equipe/Cellule",
      "Responsable Pôle/Equipe/cellule",
      "Post occupé",
      "BAP post occupé",
      "Cat.Corps poste occupé",
      "Emploi type poste occupé",
      "Quottite",
      "Statut",
      "Type contrat si contractuel",
      "Date 1er CDD",
      "Début de CDD actuel",
      "Fin de CDD actuel",
      "Durée total CDD en année/mois",
      "Obtention LA/TA",
      "Observations",
      "Situation particulière",
      "Agent en TT",
      "email SU",
      "Actions",
    ];

    // create the rows
    data.forEach((row) => {
      const employments: IEmploymentInfos[] = orderBy(
        (row.employments || []).map((e) => ({
          ...e,
          startDate: e.startDate ? new Date(e.startDate) : null,
          endDate: e.endDate ? new Date(e.endDate) : null,
        })),
        [(e: any) => getToday(e.startDate).getTime()],
        ["asc"]
      );
      let currentEmployment: IEmploymentInfos | null = null;
      let firstEmployment: IEmploymentInfos | null = null;
      if (employments.length) {
        currentEmployment = employments[employments.length - 1];
        firstEmployment = employments[0];
      }
      const formations = row.formations || [];

      const getLastResponsable = (service: IServiceInfos): IServiceInfos => {
        if (service.parent) {
          return getLastResponsable(service.parent);
        }

        return service;
      };
      let lastResponsable = row.service
        ? getLastResponsable(row.service)
        : null;

      rows.push({
        infos: {
          ...row,
        },
        tableData: {
          select: true,
          registrationNumber: row.registrationNumber,
          lastName: row.lastName ? ucFirst(row.lastName) : "",
          firstName: row.firstName ? ucFirst(row.firstName) : "",
          birthDate: changeDateFormatDDMMYYYY(row.birthDate),
          age: row.age ? row.age : "",
          sex: row.sex,
          professionalPhoneNumber: formatPhoneNumber(
            row.professionalPhoneNumber
          ),
          catAgent: row.category || "",
          corsAgent: row.corps || "",
          acronymeService: lastResponsable ? lastResponsable.acronym : "",
          designationService: lastResponsable ? lastResponsable.name : "",
          responsableService:
            lastResponsable && lastResponsable.responsable
              ? lastResponsable.responsable.firstName +
                " " +
                lastResponsable.responsable.lastName
              : "",
          responsableAdjoint:
            lastResponsable && lastResponsable.responsableAdjoint
              ? lastResponsable.responsableAdjoint.firstName +
                " " +
                lastResponsable.responsableAdjoint.lastName
              : "",
          poleEquipeCellule:
            row.service && row.service.parent ? row.service.parent.name : "",
          repsonsablePole:
            row.service && row.service.parent && row.service.parent.responsable
              ? row.service.parent.responsable.firstName +
                " " +
                row.service.parent.responsable.lastName
              : "",
          postOccupe: row.service ? row.service.name : "",
          bapPostOccupe: currentEmployment?.BAPPosteOccupe || "",
          catCorpsPostOccupe: currentEmployment?.catPostOccupe || "",
          emploi: currentEmployment ? (
            <span>
              {currentEmployment.name}{" "}
              {currentEmployment.code && (
                <a
                  href={`https://data.enseignementsup-recherche.gouv.fr/pages/fiche_emploi_type_referens_iii_itrf/?refine.referens_id=${currentEmployment.code}#top`}
                  target="_blank"
                >
                  {currentEmployment.code}
                </a>
              )}
            </span>
          ) : (
            ""
          ),
          quotite: (currentEmployment?.quotite || 0) * 100 + " %",
          statut: currentEmployment?.status || "",
          typeContrat: currentEmployment?.type || "",
          date1erCDD: firstEmployment?.startDate
            ? changeDateFormatDDMMYYYY(firstEmployment.startDate)
            : "",
          debutCDD: currentEmployment?.startDate
            ? changeDateFormatDDMMYYYY(currentEmployment.startDate)
            : "",
          finCDD: currentEmployment?.endDate
            ? changeDateFormatDDMMYYYY(currentEmployment.endDate)
            : "",
          dureeTotalCDD:
            firstEmployment &&
            firstEmployment.startDate &&
            currentEmployment &&
            currentEmployment.endDate
              ? designBetweenTwoDate(
                  firstEmployment.startDate,
                  currentEmployment.endDate
                )
              : "",
          obtentionLATA: formations
            .map((f) => f.name + " " + f.year)
            .join(" avec "),
          observations: row?.observationsDifficultJobs || "",
          situationParticuliere: (row?.observationsParticularSituations || "")
            .split("\n")
            .map((item, index) => (
              <Fragment key={index}>
                {item}
                <br />
              </Fragment>
            )),
          agentEnTT: (row?.teleworking?.days || []).some((t) => t)
            ? "oui"
            : "non",
          email: row.email,
          actions: true,
        },
      });
    });

    // set the data with the columns and rows
    _setData({
      columns,
      rows,
    });
  };

  // on row action btn pressed
  const handleRowActionSelected = ({
    row,
    action,
  }: {
    row: IAgentInfos;
    action: string;
  }) => {
    switch (action) {
      case ROW_ACTIONS.edit:
        editAgent(row);
        break;
      case ROW_ACTIONS.degrees:
        editDegrees(row);
        break;
      case ROW_ACTIONS.formations:
        editFormations(row);
        break;
      case ROW_ACTIONS.employments:
        editEmployments(row);
        break;
      case ROW_ACTIONS.inactivities:
        editInactivities(row);
        break;
      case ROW_ACTIONS.bonusVacation:
        editBonusVacation(row);
        break;
      case ROW_ACTIONS.archive:
        _setConfirmModal({
          message: "Voulez-vous archiver cet agent ?",
          confirmedAction: () => archiveAgent(row.id),
        });
        break;
      // case ROW_ACTIONS.service:
      //   addEditAgentDivision(row);
      //   break;
    }
  };

  // edit agent
  const editAgent = (agent: IAgentInfos) => {
    _setEditFullFormData(agent);
  };

  // edit degrees
  const editDegrees = (agent: IAgentInfos) => {
    _setEditCurrentAgentId(agent.id);
    _setIsEditDegreesAgentModalOpen(true);
    _setEditDegreesFormData(editDegreesFormData);
  };

  // edit formations
  const editFormations = (agent: IAgentInfos) => {
    _setEditCurrentAgentId(agent.id);
    _setIsEditFormationsAgentModalOpen(true);
    _setEditFormationsFormData(editFormationsFormData);
  };

  // edit employments
  const editEmployments = (agent: IAgentInfos) => {
    _setEditCurrentAgentId(agent.id);
    _setIsEditEmploymentsAgentModalOpen(true);
    _setEditEmploymentsFormData(editEmploymentsFormData);
  };

  // edit inactivities
  const editInactivities = (agent: IAgentInfos) => {
    _setEditCurrentAgentId(agent.id);
    _setIsEditInactivitiesAgentModalOpen(true);
    _setEditInactivitiesFormData(editInactivitiesFormData);
  };

  // edit congés @bonifiés
  const editBonusVacation = (agent: IAgentInfos) => {
    _setEditCurrentAgentId(agent.id);
    _setEditBonifiedVacationsFormData(editBonifiedVacationsFormData);
    _setIsEditBonusVacationsAgentModalOpen(true);
  };

  // delete agent
  const archiveAgent = (agentId: number) => {
    onDeleteAgent(agentId)
      .then(() => {
        onLoad();
        _setConfirmModal(null);
      })
      .catch((err) => console.error(err));
  };

  // on delete selected rows
  const deleteSelectedRows = () => {
    onDeleteAllFromIdList(selectedRowsIds)
      .then(() => {
        _setTriggerLoadDataFromDB((lastVal) => !lastVal);
        _setConfirmModal(null);
        _setSelectedRowsIds([]);
      })
      .catch((err) => console.error(err));
  };

  console.log("AGENTS : ", data);

  return (
    <BodyContainer title="Liste des Agents">
      {/* table */}
      <TableComponent
        data={data}
        onAddBtnClicked={() => _setIsAddAgentModalOpen(true)}
        onImportBtnClicked={() => {
          _setIsImportAgentsModalOpen(true);
        }}
        onDeleteSelectedRows={() =>
          _setConfirmModal({
            message: `Voulez-vous archiver ${
              selectedRowsIds.length > 1 ? "les " : "l'"
            }agent${selectedRowsIds.length > 1 ? "s " : " "} sélectionné${
              selectedRowsIds.length > 1 ? "s" : ""
            } ?`,
            confirmedAction: deleteSelectedRows,
          })
        }
        rowActions={ROW_ACTIONS}
        onRowActionSelected={(infos: { row: IAgentInfos; action: string }) =>
          handleRowActionSelected(infos)
        }
        onExport={() => {}}
        onUpdatedSelectedRowsIds={(ids: number[]) => _setSelectedRowsIds(ids)}
      />

      {editFullFormData !== null && (
        <Modal
          onClose={() => _setEditFullFormData(null)}
          width="90%"
          maxHeight="90%"
        >
          <AddEditFullAgentForm
            title={editFullFormData ? "Modifier un agent" : "Ajouter un agent"}
            formData={editFullFormData}
            actionString={editFullFormData ? "Modifier" : "Ajouter"}
            onUpdated={() => {
              _setEditFullFormData(null);
              _setTriggerLoadDataFromDB((lastVal) => !lastVal);
              _setToastMessage(
                `Agent ${editFullFormData ? "modifié" : "ajouté"} avec succès !`
              );
              _setToastVisible(true);
            }}
          />
        </Modal>
      )}

      {/* add modal */}
      {isAddAgentModalOpen && (
        <Modal
          onClose={() => _setIsAddAgentModalOpen(false)}
          width="90%"
          maxHeight="90%"
        >
          <AddEditAgentForm
            title="Ajouter un agent"
            actionString="Ajouter"
            onAgentAdded={() => {
              _setIsAddAgentModalOpen(false);
              _setTriggerLoadDataFromDB((lastVal) => !lastVal);
              _setToastMessage("Agent ajouté avec succès !");
              _setToastVisible(true);
            }}
          />
        </Modal>
      )}

      {/* edit modal */}
      {isEditAgentModalOpen && editFormData && (
        <Modal
          onClose={() => _setIsEditAgentModalOpen(false)}
          width="90%"
          maxHeight="90%"
        >
          <AddEditAgentForm
            title="Modifier l'agent"
            formData={editFormData}
            actionString="Modifier"
            onAgentUpdated={() => {
              _setIsEditAgentModalOpen(false);
              _setTriggerLoadDataFromDB((lastVal) => !lastVal);
              _setToastMessage("Agent modifié avec succès !");
              _setToastVisible(true);
            }}
          />
        </Modal>
      )}

      {/* files modal */}
      {isEditFilesAgentModalOpen && editCurrentAgentId && (
        <Modal
          onClose={() => _setIsEditFilesAgentModalOpen(false)}
          width="90%"
          maxHeight="90%"
        >
          <AddEditAgentFilesForm
            title="Dossier de l'agent"
            agentId={editCurrentAgentId}
            actionString="Ajouter"
            onAgentFilesUpdated={() => {
              _setIsEditFilesAgentModalOpen(false);
              _setTriggerLoadDataFromDB((lastVal) => !lastVal);
              _setToastMessage("Fichiers de l'agent modifiés avec succès !");
              _setToastVisible(true);
            }}
          />
        </Modal>
      )}

      {/* degrees modal */}
      {isEditDegreesAgentModalOpen && editCurrentAgentId && (
        <Modal
          onClose={() => _setIsEditDegreesAgentModalOpen(false)}
          width="90%"
          maxHeight="90%"
        >
          <AddEditAgentDegreesForm
            title="Diplômes de l'agent"
            agentId={editCurrentAgentId}
            actionString="Ajouter"
            onAgentDegreesUpdated={() => {
              _setIsEditDegreesAgentModalOpen(false);
              _setTriggerLoadDataFromDB((lastVal) => !lastVal);
            }}
          />
        </Modal>
      )}

      {/* formations modal */}
      {isEditFormationsAgentModalOpen && editCurrentAgentId && (
        <Modal
          onClose={() => _setIsEditFormationsAgentModalOpen(false)}
          width="90%"
          maxHeight="90%"
        >
          <AddEditAgentFormationsForm
            title="Formations de l'agent"
            agentId={editCurrentAgentId}
            actionString="Ajouter"
            onAgentFormationsUpdated={() => {
              _setIsEditFormationsAgentModalOpen(false);
              _setTriggerLoadDataFromDB((lastVal) => !lastVal);
            }}
          />
        </Modal>
      )}

      {/* employments modal */}
      {isEditEmploymentsAgentModalOpen && editCurrentAgentId && (
        <Modal
          onClose={() => _setIsEditEmploymentsAgentModalOpen(false)}
          width="90%"
          maxHeight="90%"
        >
          <AddEditAgentEmploymentsForm
            title="Emplois de l'agent"
            agentId={editCurrentAgentId}
            actionString="Ajouter"
            onAgentEmploymentsUpdated={() => {
              _setIsEditEmploymentsAgentModalOpen(false);
              _setTriggerLoadDataFromDB((lastVal) => !lastVal);
            }}
          />
        </Modal>
      )}

      {/* inactivités modal */}
      {isEditInactivitiesAgentModalOpen && editCurrentAgentId && (
        <Modal
          onClose={() => _setIsEditInactivitiesAgentModalOpen(false)}
          width="90%"
          maxHeight="90%"
        >
          <AddEditAgentInactivitiesForm
            title="Inactivités de l'agent"
            agentId={editCurrentAgentId}
            actionString="Ajouter"
            onAgentInactivitiesUpdated={() => {
              _setIsEditInactivitiesAgentModalOpen(false);
              _setTriggerLoadDataFromDB((lastVal) => !lastVal);
            }}
          />
        </Modal>
      )}

      {/* responsible modal */}
      {isEditResponsiblesAgentModalOpen && editCurrentAgentId && (
        <Modal
          onClose={() => _setIsEditResponsiblesAgentModalOpen(false)}
          width="90%"
          maxHeight="90%"
        >
          <AddEditAgentResponsibleForm
            title="Responsabilités de l'agent"
            agentId={editCurrentAgentId}
            actionString="Ajouter"
            onAgentResponsiblesUpdated={() => {
              _setIsEditResponsiblesAgentModalOpen(false);
              _setTriggerLoadDataFromDB((lastVal) => !lastVal);
              _setToastMessage(
                "Responsabilités de l'agent modifiées avec succès !"
              );
              _setToastVisible(true);
            }}
          />
        </Modal>
      )}

      {/* division / service - modal */}
      {isAddEditAgentDivisionModalOpen && addEditAgentDivisionFormData && (
        <Modal
          onClose={() => _setIsAddEditAgentDivisionModalOpen(false)}
          width="90%"
          maxHeight="90%"
        >
          <AddEditAgentDivisionForm agent={addEditAgentDivisionFormData} />
        </Modal>
      )}

      {/* congés bonifiés modal */}
      {isEditBonusVacationsAgentModalOpen && editCurrentAgentId && (
        <Modal onClose={() => _setIsEditBonusVacationsAgentModalOpen(false)}>
          <AddEditAgentBonusVacationsForm
            title="Congés bonifiés de l'agent"
            agentId={editCurrentAgentId}
            actionString="Ajouter"
            onAgentBonusVacationsUpdated={() => {
              _setIsEditBonusVacationsAgentModalOpen(false);
              _setTriggerLoadDataFromDB((lastVal) => !lastVal);
            }}
          />
        </Modal>
      )}

      {/* import agent modal */}
      {isImportAgentsModalOpen && (
        <Modal onClose={() => _setIsImportAgentsModalOpen(false)}>
          <ImportAgentForm
            title="Importer des agents"
            actionString="Importer"
            onAgentImported={() => {
              _setIsImportAgentsModalOpen(false);
              _setTriggerLoadDataFromDB((lastVal) => !lastVal);
              _setToastMessage(
                "Importation des agents effectuée avec succès !"
              );
              _setToastVisible(true);
              onLoad();
            }}
          />
        </Modal>
      )}

      {/* TOAST */}
      {toastVisible && toastMessage && (
        <ToastAlert
          text={toastMessage}
          handleOk={() => {
            _setToastVisible(false);
          }}
          endedTimer={() => {
            _setToastVisible(false);
          }}
        />
      )}

      {/* confirm modal */}
      {confirmModal && (
        <Modal onClose={() => _setConfirmModal(null)}>
          <ConfirmModalContainer>
            <div className="message">{confirmModal.message}</div>
            <div className="buttons">
              <Button
                text="Oui"
                onClick={() => {
                  confirmModal.confirmedAction();
                  _setToastMessage(
                    "Agent" +
                      (selectedRowsIds.length > 1 ? "s" : "") +
                      " archivé" +
                      (selectedRowsIds.length > 1 ? "s" : "") +
                      " avec succès !"
                  );
                  _setToastVisible(true);
                }}
              />
              <Button text="Non" onClick={() => _setConfirmModal(null)} />
            </div>
          </ConfirmModalContainer>
        </Modal>
      )}
    </BodyContainer>
  );
};

export default Agents;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const ConfirmModalContainer = styled.div`
  padding: 20px;

  .message {
    text-align: center;
    color: ${COLOR_TEXT};
    font-size: 0.9rem;

    // ========= MEDIA QUERIES - ConfirmModalContainer ============
    @media (max-width: 1000px) {
      font-size: 2.5vw;
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
`;
