import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import FormBase from "../../../components/form/FormBase";
import Button from "../../../components/Button";
import FormErrorMessageContainer from "../../../components/form/FormErrorMessageContainer";
import {
  COLOR_RED_BRIGHT,
  COLOR_TEXT,
  COLOR_TITLE,
} from "../../../constants/cts_colors";
import Modal from "../../../components/Modal";
import { MdCloudUpload } from "react-icons/md";
import { useDropzone } from "react-dropzone";
import readXlsxFile from "read-excel-file";
import { useAgents } from "../../../common/contexts/agentContext";
import { IAgentFormValues } from "../../../interfaces/agent";
import { useDivisions } from "../../../common/contexts/divisionContext";
import { DIVISION } from "../../../constants/cts_divisions";

const ImportAgentForm = ({
  title,
  actionString,
  onAgentImported,
}: {
  title: string;
  formData?: IAgentFormValues;
  actionString: string;
  onAgentImported?: Function;
}) => {
  const [confirmModal, _setConfirmModal] = useState<{
    message: string;
    confirmedAction: Function;
    params?: Object;
  } | null>(null);

  const { onUpdateDivisionFromAcronym } = useDivisions();

  const { onCheckRegistrationNumberOfAgent, onUpdateAgent } = useAgents();

  // on drop
  const onDrop = useCallback((acceptedFiles: any) => {
    readXlsxFile(acceptedFiles[0]).then((rows) => {
      /*0: "No_dossier\n(nouveau)"
      1: "No_dossier\n(ancien)"
      2: "NOM"
      3: "PRENOM"
      4: "DATE DE NAISSANCE"
      5: "AGE\n(formule)"
      6: "Genre"
      7: "Tél fixe"
      8: "Tél pro."
      9: "Catégorie agent"
      10: "Corps agent"
      11: "Acronyme service DLM"
      12: "Désignation service DLM\n(formule)"
      13: "Responsable du service\n(formule)"
      14: "Responsable adjoint du service\n(formule)"
      15: "Pôle/Equipe/Cellule"
      16: "Responsable Pôle/Equipe/Cellule"
      17: "Poste occupé"
      18: "BAP poste occupé"
      19: "Cat./Corps poste occupé"
      20: "Emploi type poste occupé"
      21: "Quotité"
      22: "Statut"
      23: "Type contrat si contractuel"
      24: "Date 1er CDD"
      25: "Début de CDD actuel"
      26: "Fin de CDD actuel"
      27: "Durée totale CDD en année/mois\n(formule)"
      28: "Obtention LA/TA"
      29: "Observations"
      30: "Situation particulière"
      31: "Agent en TT ?"
      32: "email SU"*/

      const agents = rows.slice(1, rows.length).map((parse: any) => {
        //console.log(parse)
        const agent: any = {};

        agent.registrationNumber = "" + parse[0];
        agent.firstName = parse[3];
        agent.lastName = parse[2];
        agent.birthDate = new Date(parse[4]);
        agent.sex = parse[6];
        agent.professionalPhoneNumber = parse[8] || parse[7];
        agent.email = parse[32];
        agent.observationsDifficultJobs = parse[29];
        agent.observationsParticularSituations = parse[30];
        agent.bonusVacation = null;
        agent.departureDate = null;
        agent.departureReason = null;
        if (parse[26] && parse[26] !== "non concerné") {
          agent.departureDate = new Date(parse[26]);
          agent.departureReason = "Fin de CDD";
        }

        agent.categorieAgent = parse[9];
        agent.corpsAgent = parse[10];
        agent.acronymeServiceDLM = parse[11];
        agent.nomServiceDLM = parse[12];
        agent.responsableService = parse[13];
        agent.responsableAdjointService = parse[14];
        agent.pole = parse[15];
        agent.responsablePole = parse[16];
        agent.posteOccupe = parse[17];
        agent.BAPPosteOccupe = parse[18];
        agent.catPostOccupe = parse[19];
        agent.emploiType = parse[20];
        agent.quotite = parse[21];
        agent.statut = parse[22];
        agent.typeContrat = parse[23];
        agent.date1erCDD = parse[24];
        agent.dateCDDactuel = parse[25];
        agent.dateFinCDDactuel = parse[26];
        agent.dureeCDDTotal = parse[27];
        agent.obtentionLATA = parse[28];
        agent.agentTT = parse[31];

        agent.raw = parse;

        return agent;
      });

      _setData(agents);
      console.log("FINAL PARSED DATA ", agents);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const { onCreateAgent } = useAgents();

  const [errorMessage, _setErrorMessage] = useState<string | null>(null);

  // on submit
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (data && data.length !== 0) {
      try {
        for (let i = 0; i < data.length; i++) {
          const d = data[i];

          // CHECK IF MATRICULE EXISTS
          let agentId = await onCheckRegistrationNumberOfAgent(
            d.registrationNumber
          );
          if (agentId !== -1) {
            console.log({
              id: agentId,
              ...d,
            });
            // IF YES UPDATE
            await onUpdateAgent({
              id: agentId,
              ...d,
            });
          } else {
            // IF NOT CREATE
            agentId = await onCreateAgent(d);
          }

          if (d.acronymeServiceDLM) {
            const responsable = data.find(
              (dat) =>
                (dat.firstName || "").toLowerCase() +
                  " " +
                  (dat.lastName || "").toLowerCase() ===
                (d.responsableService || "").toLowerCase()
            );
            const responsableAdjoint = data.find(
              (dat) =>
                (dat.firstName || "").toLowerCase() +
                  " " +
                  (dat.lastName || "").toLowerCase() ===
                (d.responsableAdjointService || "").toLowerCase()
            );

            await onUpdateDivisionFromAcronym({
              acronyme: d.acronymeServiceDLM,
              nom: d.nomServiceDLM,
              responsable: d.responsableService,
              responsableNumber: responsable?.registrationNumber || "",
              responsableAdjoint: d.responsableAdjointService,
              responsableAdjointNumber:
                responsableAdjoint?.registrationNumber || "",
            });
          }

          const responsablePole = data.find(
            (dat) =>
              (dat.firstName || "").toLowerCase() +
                " " +
                (dat.lastName || "").toLowerCase() ===
              (d.responsablePole || "").toLowerCase()
          );
          await onUpdateDivisionFromAcronym({
            acronyme: d.pole,
            nom: d.pole,
            responsable: d.responsablePole,
            responsableNumber: responsablePole?.registrationNumber || "",
            type: DIVISION.department.type,
            parentAcronyme: d.acronymeServiceDLM,
          });
          if (d.posteOccupe) {
            await onUpdateDivisionFromAcronym({
              acronyme: d.posteOccupe,
              nom: d.posteOccupe,
              agent: `${d.firstName} ${d.lastName}`,
              agentNumber: d?.registrationNumber || "",
              type: DIVISION.team.type,
              parentAcronyme: d.pole,
            });
          }
        }

        if (onAgentImported) {
          // IF IMPORTED
          onAgentImported();
        }
      } catch (err) {
        console.log("err", err);
        //_setErrorMessage(err);
      }
    }
  };

  const [data, _setData] = useState<any[] | null>([]);

  const [displayDataLength, _setDisplayDataLength] = useState<boolean>(false);

  useEffect(() => {
    // console.log(data?.length);
    if (data) _setDisplayDataLength(data?.length > 0);
  }, [data]);

  return (
    <FormBase title={title}>
      <FormContainer>
        <FormScrollable>
          <Form onSubmit={handleSubmit}>
            <div className="inputs-container">
              {/* type file */}
              <div className="input-import" {...getRootProps()}>
                {/* <div className="input-import"> */}
                <input
                  {...getInputProps()}
                  type="file"
                  name="importFile"
                  id="importFile"
                  className="inputfile"
                  accept=".xlsx"
                  // onChange={handleFileUpload}
                />
                <label htmlFor="importFile">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <MdCloudUpload size={32} />
                  </div>
                  {displayDataLength && (
                    <p style={{ marginTop: 16, textAlign: "center" }}>
                      {data?.length} agent
                      {data && data.length > 1 ? "s" : ""} à importer de Pollux
                    </p>
                  )}
                  {data?.length === 0 && <p>Importer le fichier des agents</p>}
                </label>
              </div>

              {/* form errors */}
              {errorMessage && (
                <FormErrorMessageContainer errorMessage={errorMessage} />
              )}
              {/* submit btn */}
              <div className="submit-btn-container">
                <Button
                  text={actionString}
                  onClick={handleSubmit}
                  disabled={data?.length !== 0 ? false : true}
                />
              </div>
            </div>
          </Form>
        </FormScrollable>
      </FormContainer>

      {/* confirm modal */}
      {confirmModal && (
        <Modal onClose={() => _setConfirmModal(null)}>
          <ConfirmModalContainer>
            <div className="message">{confirmModal.message}</div>
            <div className="buttons">
              <Button
                text="Oui"
                onClick={() => confirmModal.confirmedAction()}
              />
              <Button text="Non" onClick={() => _setConfirmModal(null)} />
            </div>
          </ConfirmModalContainer>
        </Modal>
      )}
    </FormBase>
  );
};

export default ImportAgentForm;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const FormContainer = styled.div`
  padding: 40px 10%;
`;

const FormScrollable = styled.div`
  width: 100%;
  max-height: 40vh;
  overflow: auto;
  padding: 0px 20px;
`;

const Form = styled.form`
  .inputs-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.4em;

    .input-import {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .inputfile + label {
      font-size: 1em;
      font-weight: 700;
      color: white;
      background-color: ${COLOR_RED_BRIGHT};
      padding: 48px 64px;
      display: inline-block;
      border-radius: 8px;
      border: 1px solid ${COLOR_RED_BRIGHT};
      transition: 250ms;
      outline: none;
    }

    .inputfile:focus + label,
    .inputfile + label:hover {
      background-color: white;
      color: ${COLOR_RED_BRIGHT};
      margin: auto;
    }

    .inputfile {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    .inputfile + label {
      cursor: pointer; /* "hand" cursor */
    }

    .inputfile:focus + label {
      outline: 1px dotted #000;
      outline: -webkit-focus-ring-color auto 5px;
    }

    .latest-vacations {
      padding: 12px 128px;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }

    .submit-btn-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const HistoriqueFormations = styled.div`
  h4 {
    text-align: center;
    margin-bottom: 8px;
    font-weight: bold;
    color: ${COLOR_TITLE};
  }
`;

const StatusCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 16px 32px;
  border-radius: 8px;
  color: ${COLOR_TEXT};
  border: 1px solid ${COLOR_TEXT};

  h4 {
    margin-bottom: 0;
    color: ${COLOR_TEXT};
  }
`;

const EditDeleteIcons = styled.div`
  position: absolute;
  top: 0;
  cursor: pointer;
  padding: 4px;
  color: ${COLOR_TEXT};

  :hover {
    color: ${COLOR_RED_BRIGHT};
  }
`;

const ConfirmModalContainer = styled.div`
  padding: 20px;

  .message {
    text-align: center;
    color: ${COLOR_TEXT};
    font-size: 0.9rem;

    // ========= MEDIA QUERIES - ConfirmModalContainer ============
    @media (max-width: 1000px) {
      font-size: 2.5vw;
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
`;
