import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";
import FormBase from "../../../../components/form/FormBase";
import Button from "../../../../components/Button";
import FormErrorMessageContainer from "../../../../components/form/FormErrorMessageContainer";
import FormLabel from "../../../../components/form/FormLabel";
import FormInputContainer from "../../../../components/form/FormInputContainer";
import { FaPen, FaColumns } from "react-icons/fa";
import { DIVISION } from "../../../../constants/cts_divisions";
// import { MdOutlineSchema } from "react-icons/md";
import { IDepartmentFormValues } from "../../../../interfaces/department";
import { useDivisions } from "../../../../common/contexts/divisionContext";
import { IDivisionInfos } from "../../../../interfaces/division";
import FormInfoMessage from "../../../../components/form/FormInfoMessage";

const AddEditDepartmentForm = ({
  title,
  formData,
  currentService,
  actionString,
  onDepartmentAdded,
  onDepartmentUpdated,
}: {
  title: string;
  formData?: IDepartmentFormValues;
  currentService: IDivisionInfos | null;
  actionString: string;
  onDepartmentAdded?: Function;
  onDepartmentUpdated?: Function;
}) => {
  const initialFormState = {
    name: null,
    acronym: null,
    type: null,
    // parentDivisionId: currentService ? currentService.id : null,
  };
  const [formValues, _setFormValues] =
    useState<IDepartmentFormValues>(initialFormState);
  const [errorMessage, _setErrorMessage] = useState<string | null>(null);
  const { onCreateDivision, onUpdateDivision, onGetDivisionChildren } =
    useDivisions();
  const [isEditMode, _setIsEditMode] = useState<boolean>(false);
  // const [ parentDivisions, _setParentDivisions ] = useState<IDivisionInfos[]>([]);

  // check if there is data already given (edit mode)
  // if so, set the form values
  useEffect(() => {
    if (formData) {
      _setFormValues({ ...formData });
      _setIsEditMode(true);
    }
  }, []);

  // on submit
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (isEditMode) {
      // if is on edit mode, update the division
      onUpdateDivision({ ...formValues })
        .then(() => {
          if (onDepartmentUpdated) {
            onDepartmentUpdated();
          }
        })
        .catch((err) => {
          _setErrorMessage(err);
        });
    } else {
      // if it's not on edit mode, create the division
      onCreateDivision({ ...formValues })
        .then(() => {
          if (onDepartmentAdded) {
            onDepartmentAdded();
          }
        })
        .catch((err) => {
          _setErrorMessage(err);
        });
    }
  };

  // get service divisions
  // useEffect(() => {
  //   if (currentService) {
  //     onGetDivisionChildren(currentService.id)
  //       .then((response) => _setParentDivisions(response))
  //       .catch((error) => console.error(error))
  //   }
  // }, [currentService])

  // on change
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    _setFormValues({
      ...formValues,
      [name]: value ? value : null,
    });
  };

  return (
    <FormBase title={title}>
      <FormContainer>
        <div className="service-info">
          <FormInfoMessage text={currentService?.name || ""} />
          {formData && isEditMode && (
            <FormInfoMessage
              text={`${
                formData.type ? formData.type.toUpperCase() + " " : ""
              }| ${formData.name}`}
            />
          )}
        </div>
        <FormScrollable>
          <Form onSubmit={handleSubmit}>
            <div className="inputs-container">
              {/* name */}
              <div>
                <FormLabel htmlFor="name" text="Nom" />
                <FormInputContainer
                  icon={<FaPen />}
                  input={
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Nom"
                      onChange={handleChange}
                      value={formValues.name ? formValues.name : ""}
                    />
                  }
                />
              </div>

              {/* division type */}
              <div>
                <FormLabel htmlFor="type" text="Type" />
                <FormInputContainer
                  icon={<FaColumns />}
                  input={
                    <select
                      id="type"
                      value={formValues.type ? formValues.type : ""}
                      name="type"
                      onChange={handleChange}
                    >
                      <option value="">Aucun</option>
                      <option value={DIVISION.department.type}>Pôle</option>
                      <option value={DIVISION.team.type}>Équipe</option>
                    </select>
                  }
                />
              </div>

              {/* parent division */}
              {/* <div>
                <FormLabel htmlFor="parentDivisionId" text="La branche" />
                <FormInputContainer
                  icon={<MdOutlineSchema />}
                  input={
                    <select id="parentDivisionId" value={formValues.parentDivisionId ? formValues.parentDivisionId : ""} name="parentDivisionId" onChange={handleChange}>
                      <option value={currentService ? currentService.id : ''}>
                        Appartient à ce service
                      </option>
                      {
                        parentDivisions &&
                        parentDivisions.length > 0 &&
                        parentDivisions.map((parentDivision) =>
                          <option
                            key={`parent-division-${parentDivision.id}`}
                            value={parentDivision.id}>
                              {`Niv. ${(parentDivision.level - DIVISION.service.level) * -1} | ${parentDivision.type ? parentDivision.type.toUpperCase() + ' ' : 'Pôle/Équipe/Cellule ' }| ${parentDivision.name}`}
                          </option>
                        )
                      }
                    </select>
                  }
                />
              </div> */}

              {/* form errors */}
              {errorMessage && (
                <FormErrorMessageContainer errorMessage={errorMessage} />
              )}

              {/* submit btn */}
              <div className="submit-btn-container">
                <Button text={actionString} onClick={handleSubmit} />
              </div>
            </div>
          </Form>
        </FormScrollable>
      </FormContainer>
    </FormBase>
  );
};

export default AddEditDepartmentForm;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const FormContainer = styled.div`
  padding: 40px 10%;

  .service-info {
    margin-bottom: 10px;
    padding: 0px 20px;
  }
`;

const FormScrollable = styled.div`
  max-height: 40vh;
  overflow: auto;
  padding: 0px 20px;
`;

const Form = styled.form`
  .inputs-container {
    display: flex;
    flex-direction: column;
    gap: 1.4em;

    .submit-btn-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
