import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";
import FormBase from "../../../components/form/FormBase";
import Button from "../../../components/Button";
import FormErrorMessageContainer from "../../../components/form/FormErrorMessageContainer";
import FormLabel from "../../../components/form/FormLabel";
import FormInputContainer from "../../../components/form/FormInputContainer";
import {
  COLOR_RED_BRIGHT,
  COLOR_TEXT,
  COLOR_TITLE,
} from "../../../constants/cts_colors";
import Modal from "../../../components/Modal";
import {
  IEmploymentFormValues,
  IEmploymentInfos,
} from "../../../interfaces/employment";
import { useEmployments } from "../../../common/contexts/employmentContext";
import ToastAlert from "../../../components/ToastAlert";
import { IReferensJobInfos } from "../../../interfaces/referensJob";
import { useReferensJobs } from "../../../common/contexts/referensJobContext";
import { MdOutlineBusinessCenter } from "react-icons/md";
import FormInfoMessage from "../../../components/form/FormInfoMessage";

const AddEditEmploymentsAgentForm = ({
  title,
  agentId,
  formData,
  actionString,
  onAgentEmploymentsUpdated,
}: {
  title: string;
  agentId: number;
  formData?: IEmploymentFormValues;
  actionString: string;
  onAgentEmploymentsUpdated?: Function;
}) => {
  const [confirmModal, _setConfirmModal] = useState<{
    message: string;
    confirmedAction: Function;
    params?: Object;
  } | null>(null);

  const initialFormState = {
    name: null,
    code: null,
    family: null,
    agentId: agentId,
    subFamily: null,
    startDate: null,
    endDate: null,
    referensJobId: null,
  };

  // toast
  const [toastVisible, _setToastVisible] = useState<boolean>(false);
  const [toastMessage, _setToastMessage] = useState<string>("");

  const { onGetAllReferensJobs } = useReferensJobs();

  const [referensJobs, _setReferensJobs] = useState<Array<IReferensJobInfos>>(
    []
  );

  const [formValues, _setFormValues] =
    useState<IEmploymentFormValues>(initialFormState);

  const [errorMessage, _setErrorMessage] = useState<string | null>(null);

  const {
    onGetAllEmploymentsFromAgent,
    onCreateEmployment,
    onDeleteEmployment,
    onUpdateEmployment,
  } = useEmployments();

  const { onGetReferensJob } = useReferensJobs();

  const [employments, _setEmployments] = useState<Array<IEmploymentInfos>>([]);
  const [actualReferensJob, _setActualReferensJob] =
    useState<IReferensJobInfos>();

  const [isEditMode, _setIsEditMode] = useState<boolean>(false);

  // check if there is data already given (edit mode)
  // if so, set the form values
  useEffect(() => {
    if (agentId) {
      onGetAllEmploymentsFromAgent(agentId).then((response: any) => {
        _setEmployments(response);
      });
    }

    onGetAllReferensJobs().then((response: any) => {
      _setReferensJobs(response);
    });

    if (formData) {
      _setFormValues(formData);
      _setIsEditMode(true);
    }
  }, []);

  // delete agent inactivity
  const deleteAgentEmployment = (agentEmploymentId: number) => {
    onDeleteEmployment(agentEmploymentId)
      .then(() => {
        _setToastMessage("Emplois supprimé avec succès !");
        _setToastVisible(true);
        _setConfirmModal(null);
        _setFormValues(initialFormState);
        onGetAllEmploymentsFromAgent(agentId).then((response: any) => {
          _setEmployments(response);
        });
      })
      .catch((err) => console.error(err));
  };

  // on delete
  const handleDelete = (agentEmploymentId: number) => {
    _setConfirmModal({
      message: "Voulez-vous supprimer cet emploi ?",
      confirmedAction: () => deleteAgentEmployment(agentEmploymentId),
    });
  };

  // on submit
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!isEditMode) {
      onCreateEmployment({
        name: actualReferensJob?.jobTitle,
        code: actualReferensJob?.codeJob,
        family: actualReferensJob?.professionalFamily,
        agentId: agentId,
        subFamily: actualReferensJob?.businessFamilyCode,
        startDate: formValues.startDate,
        endDate: formValues.endDate,
        referensJobId: actualReferensJob?.id,
      })
        .then(() => {
          _setToastMessage("Emplois ajouté avec succès !");
          _setToastVisible(true);
          _setFormValues(initialFormState);
          onGetAllEmploymentsFromAgent(agentId).then((response: any) => {
            _setEmployments(response);
          });
        })
        .catch((err) => {
          _setErrorMessage(err);
        });
    } else {
      // if is on edit mode, update the agent inactivity
      onUpdateEmployment({
        name: actualReferensJob?.jobTitle,
        code: actualReferensJob?.codeJob,
        family: actualReferensJob?.professionalFamily,
        agentId: agentId,
        subFamily: actualReferensJob?.businessFamilyCode,
        startDate: formValues.startDate,
        endDate: formValues.endDate,
        referensJobId: actualReferensJob?.id,
      }).then(() => {
        _setToastMessage("Emplois mis à jour avec succès !");
        _setToastVisible(true);
        _setFormValues(initialFormState);
        onGetAllEmploymentsFromAgent(agentId).then((response: any) => {
          _setIsEditMode(false);
          _setEmployments(response);
        });
      });
    }
  };

  // on change
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    _setFormValues({
      ...formValues,
      [name]: value ? value : null,
    });

    if (name === "referensJobId")
      onGetReferensJob(value).then((response: IReferensJobInfos) => {
        // console.log(response);
        _setActualReferensJob(response);
      });
  };

  return (
    <FormBase title={title}>
      <FormContainer>
        <FormScrollable>
          <Form onSubmit={handleSubmit}>
            <div className="inputs-container">
              <HistoriqueFormations>
                <h4>- Emplois -</h4>
                {employments && employments.length > 0 && (
                  <div className="latest-vacations">
                    {employments.map((employment, key) => {
                      return (
                        <EmploymentCard key={key}>
                          <EditDeleteIcons
                            style={{ left: 0 }}
                            className="ri-delete-bin-line"
                            onClick={() => {
                              handleDelete(employment.id);
                            }}
                          ></EditDeleteIcons>
                          <EditDeleteIcons
                            style={{ right: 0 }}
                            className="ri-edit-line"
                            onClick={() => {
                              // console.log(employment);
                              _setIsEditMode(true);
                              _setFormValues(employment);
                            }}
                          ></EditDeleteIcons>

                          <h4>
                            {employment.name} - {employment.code}
                          </h4>
                          <p>-</p>
                          <h4>{employment.family}</h4>
                          <h4>{employment.subFamily}</h4>
                          <h4>
                            {new Date(
                              employment?.startDate || ""
                            ).toLocaleDateString("fr-FR", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })}
                          </h4>
                          {employment.endDate && (
                            <h4>
                              {new Date(employment.endDate).toLocaleDateString(
                                "fr-FR",
                                {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                }
                              )}
                            </h4>
                          )}
                        </EmploymentCard>
                      );
                    })}
                  </div>
                )}
                {!employments ||
                  (employments.length < 1 && (
                    <p
                      style={{
                        textAlign: "center",
                        color: COLOR_TEXT,
                        paddingTop: 12,
                      }}
                    >
                      Aucun emplois pour le moment.
                    </p>
                  ))}
              </HistoriqueFormations>

              <FormLabel htmlFor="referensJobId" text="Emploi Référent" />
              <FormInputContainer
                icon={<MdOutlineBusinessCenter />}
                input={
                  <select
                    id="referensJobId"
                    value={
                      formValues.referensJobId ? formValues.referensJobId : ""
                    }
                    name="referensJobId"
                    onChange={handleChange}
                  >
                    <option value="">Selectionner un emploi référent</option>
                    {referensJobs &&
                      referensJobs.map((referensJob, key) => {
                        return (
                          <option key={key} value={referensJob.id}>
                            {referensJob.jobTitle} - {referensJob.codeJob}
                          </option>
                        );
                      })}
                  </select>
                }
              />

              {/* start date */}
              <div>
                <FormLabel htmlFor="startDate" text="Date de début" />
                <FormInputContainer
                  input={
                    <input
                      id="startDate"
                      type="date"
                      onChange={handleChange}
                      name="startDate"
                      value={
                        formValues.startDate
                          ? new Date(formValues.startDate)
                              .toISOString()
                              .split("T")[0]
                          : ""
                      }
                    />
                  }
                />
                <FormInfoMessage text="Veuillez cliquer sur l'icône pour changer la date" />
              </div>

              {/* end date */}
              <div>
                <FormLabel htmlFor="endDate" text="Date de fin" />
                <FormInputContainer
                  input={
                    <input
                      id="endDate"
                      type="date"
                      onChange={handleChange}
                      name="endDate"
                      value={
                        formValues.endDate
                          ? new Date(formValues.endDate)
                              .toISOString()
                              .split("T")[0]
                          : ""
                      }
                    />
                  }
                />
                <FormInfoMessage text="Veuillez cliquer sur l'icône pour changer la date" />
              </div>

              {/* form errors */}
              {errorMessage && (
                <FormErrorMessageContainer errorMessage={errorMessage} />
              )}
              {/* submit btn */}
              <div className="submit-btn-container">
                <Button
                  text={!isEditMode ? actionString : "Modifier"}
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </Form>
        </FormScrollable>
      </FormContainer>

      {/* TOAST */}
      {toastVisible && toastMessage && (
        <ToastAlert
          text={toastMessage}
          handleOk={() => {
            _setToastVisible(false);
          }}
          endedTimer={() => {
            _setToastVisible(false);
          }}
        />
      )}

      {/* confirm modal */}
      {confirmModal && (
        <Modal onClose={() => _setConfirmModal(null)}>
          <ConfirmModalContainer>
            <div className="message">{confirmModal.message}</div>
            <div className="buttons">
              <Button
                text="Oui"
                onClick={() => confirmModal.confirmedAction()}
              />
              <Button text="Non" onClick={() => _setConfirmModal(null)} />
            </div>
          </ConfirmModalContainer>
        </Modal>
      )}
    </FormBase>
  );
};

export default AddEditEmploymentsAgentForm;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const FormContainer = styled.div`
  padding: 40px 10%;
`;

const FormScrollable = styled.div`
  width: 100%;
  max-height: 40vh;
  overflow: auto;
  padding: 0px 20px;
`;

const Form = styled.form`
  .inputs-container {
    display: flex;
    flex-direction: column;
    gap: 1.4em;

    .latest-vacations {
      padding: 12px 128px;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }

    .submit-btn-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const HistoriqueFormations = styled.div`
  h4 {
    text-align: center;
    margin-bottom: 8px;
    font-weight: bold;
    color: ${COLOR_TITLE};
  }
`;

const EmploymentCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 16px 32px;
  border-radius: 8px;
  color: ${COLOR_TEXT};
  border: 1px solid ${COLOR_TEXT};

  h4 {
    margin-bottom: 0;
    color: ${COLOR_TEXT};
  }
`;

const EditDeleteIcons = styled.div`
  position: absolute;
  top: 0;
  cursor: pointer;
  padding: 4px;
  color: ${COLOR_TEXT};

  :hover {
    color: ${COLOR_RED_BRIGHT};
  }
`;

const ConfirmModalContainer = styled.div`
  padding: 20px;

  .message {
    text-align: center;
    color: ${COLOR_TEXT};
    font-size: 0.9rem;

    // ========= MEDIA QUERIES - ConfirmModalContainer ============
    @media (max-width: 1000px) {
      font-size: 2.5vw;
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
`;
