import {
  IDivisionChangeParent,
  IDivisionCreateUpdate,
} from "../interfaces/division";
import { instanceAxios } from "../utils/axios-api";

// CREATE ONE
export const onCreateDivisionApi = async ({
  name,
  acronym,
  type,
  parentDivisionId,
}: IDivisionCreateUpdate) => {
  return instanceAxios
    .post("/divisions/create-division", {
      name,
      acronym,
      type,
      parentDivisionId,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};

// UPDATE ONE
export const onUpdateDivisionApi = async ({
  id,
  name,
  acronym,
  type,
  parentDivisionId,
}: IDivisionCreateUpdate) => {
  return instanceAxios
    .put("/divisions/update-division/" + id, {
      name,
      acronym,
      type,
      parentDivisionId,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};

// CHANGE PARENT DIVISION
export const onChangeParentDivisionApi = async ({
  divisionId,
  oldParentDivisionId,
  newParentDivisionId,
}: IDivisionChangeParent) => {
  return instanceAxios
    .put("/divisions/change-parent-division/", {
      divisionId,
      oldParentDivisionId,
      newParentDivisionId,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};

// GET ONE DIVISION BY ID
export const onGetOneDivisionByIdApi = async (id: number) => {
  return instanceAxios
    .get("/divisions/get-one-division-by-id/" + id)
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};

// GET ALL DIVISION
export const onGetAllDivisionsApi = async () => {
  return instanceAxios
    .get("/divisions/get-all-divisions/")
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};

// GET ALL DIVISION BY TYPE
export const onGetAllDivisionsByTypeApi = async (type: string) => {
  return instanceAxios
    .get("/divisions/get-all-divisions-by-type/" + type)
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};

// GET DIVISION FROM ACRONYM
export const onGetDivisionFromAcronymApi = async (acronym: string) => {
  return instanceAxios
    .get("/divisions/get-division-from-acronym/" + acronym)
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};

// GET DIVISION FROM ACRONYM
export const onGetDivisionFromReferenceApi = async ({
  reference,
  checkName,
}: {
  reference: string;
  checkName: string;
}) => {
  return instanceAxios
    .post("/divisions/get-division-from-reference/" + reference, {
      checkName,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};

// GET ALL DIVISION BY TYPES
export const onGetAllDivisionsByTypesApi = async (types: string[]) => {
  return instanceAxios
    .get("/divisions/get-all-divisions-by-types/" + types)
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};

// GET DIVISION CHILDREN
export const onGetDivisionChildrenApi = async (divisionId: number) => {
  return instanceAxios
    .get("/divisions/get-division-children/" + divisionId)
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};

// GET DIVISION BRANCH
export const onGetDivisionBranchApi = async (
  divisionId: number,
  date?: Date
) => {
  return instanceAxios
    .get("/divisions/get-division-branch/", {
      params: { divisionId, date },
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};

export const onUpdateDivisionFromAcronymApi = async ({
  acronyme,
  nom,
  responsable,
  responsableNumber,
  responsableAdjoint,
  responsableAdjointNumber,
  type,
  parentAcronyme,
  agent,
  agentNumber,
}: {
  acronyme: string;
  nom: string;
  responsable: string;
  responsableNumber: string;
  responsableAdjoint: string;
  responsableAdjointNumber: string;
  type?: string;
  parentAcronyme?: string;
  agent?: string;
  agentNumber?: string;
}) => {
  return instanceAxios
    .put("/divisions/sync", {
      acronyme,
      nom,
      responsable,
      responsableNumber,
      responsableAdjoint,
      responsableAdjointNumber,
      type,
      parentAcronyme,
      agent,
      agentNumber,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};
