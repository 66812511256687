import React, { useCallback, useEffect, useState } from "react";
import TableComponent from "../../../components/table/TableComponent";
import { IDivisionInfos } from "../../../interfaces/division";
import Modal from "../../../components/Modal";
import { useDivisions } from "../../../common/contexts/divisionContext";
import { DIVISION } from "../../../constants/cts_divisions";
import AddEditResponsibleForm from "./addEditResponsibleForm";
import ToastAlert from "../../../components/ToastAlert";
import EditDivisionForm from "./editDivisionForm";
import {
  RESPONSIBLE_ASSISTANT_TYPE,
  RESPONSIBLE_TYPE,
} from "../../../constants/cts_responsibles";
import styled from "styled-components";

const ServicesTable = ({
  onClickOpenServiceDepartmentsModal,
}: {
  onClickOpenServiceDepartmentsModal: Function;
}) => {
  // TABLE
  const [tableData, _setTableData] = useState<{
    columns: any;
    rows: any;
  } | null>(null);
  const ROW_ACTIONS = {
    edit: "Modifier",
    responsible: "Responsables",
    showDepartments: "Afficher les pôles du service",
  };
  // MODAL
  const [isModalOpenEdit, _setIsModalOpenEdit] = useState<boolean>(false);
  const [isModalOpenResponsible, _setIsModalOpenResponsible] =
    useState<boolean>(false);
  // DIRECTION
  const [direction, _setDirection] = useState<IDivisionInfos | null>(null);
  // SERVICES
  const [services, _setServices] = useState<IDivisionInfos[]>([]);
  // DIVISION
  const { onGetAllDivisionsByType } = useDivisions();
  const [currentDivision, _setCurrentDivision] = useState<IDivisionInfos>();
  // EDIT DATA
  const [editFormData, _setEditFormData] = useState<IDivisionInfos | null>(
    null
  );
  // toast
  const [toastMessage, _setToastMessage] = useState<string>("");
  // export list data
  const [exportListData, _setExportListData] = useState<IDivisionInfos[]>([]);

  // LOAD DIRECTION AND SERVICES
  const loadData = useCallback(async () => {
    const directionData = await onGetAllDivisionsByType(
      DIVISION.direction.type
    );
    const servicesData = await onGetAllDivisionsByType(DIVISION.service.type);
    const tableData = [];

    if (directionData && directionData.length > 0) {
      tableData.push(directionData[0]);
    }

    if (servicesData && servicesData.length > 0) {
      // sort by acronym
      servicesData.sort((a, b) => {
        if (a.acronym && b.acronym) {
          if (a.acronym < b.acronym) {
            return -1;
          }
          if (a.acronym > b.acronym) {
            return 1;
          }
        }
        return 0;
      });
      servicesData.forEach((service) => {
        tableData.push(service);
      });
    }

    _setExportListData(tableData);

    loadTableData(tableData);
  }, [onGetAllDivisionsByType]);

  // GET AND SET ALL THE DIRECTION ON FIRST RENDER
  useEffect(() => {
    loadData();
  }, [loadData]);

  // HANDLE WHEN ACTION SELECTED FOR THE ROW
  const handleRowActionSelected = ({
    row,
    action,
  }: {
    row: IDivisionInfos;
    action: string;
  }) => {
    switch (action) {
      case ROW_ACTIONS.edit:
        edit(row);
        break;
      case ROW_ACTIONS.responsible:
        editResponsible(row);
        break;
      case ROW_ACTIONS.showDepartments:
        onClickOpenServiceDepartmentsModal(row);
        break;
    }
  };

  // EDIT
  const edit = (division: IDivisionInfos) => {
    _setIsModalOpenEdit(true);
    _setEditFormData(division);
  };

  // EDIT RESPONSABLE
  const editResponsible = (division: IDivisionInfos) => {
    _setIsModalOpenResponsible(true);
    _setCurrentDivision(division);
    _setEditFormData(division);
  };

  // LOAD TABLE'S DATA
  const loadTableData = (data: IDivisionInfos[]) => {
    const rows: any = [];
    const columns: string[] = [
      "Acronyme Service",
      "Désignation service DLM",
      "Responsable du service",
      "Responsable adjoint du service",
      "Actions",
    ];

    // create the rows
    data.forEach((row) => {
      const responsible = (row.responsibles || []).find(
        (r) => r.responsibleType === RESPONSIBLE_TYPE
      );
      const responsibleAdjoint = (row.responsibles || []).find(
        (r) => r.responsibleType === RESPONSIBLE_ASSISTANT_TYPE
      );

      rows.push({
        infos: {
          ...row,
        },
        tableData: {
          acronym: row.acronym,
          name: row.name,
          responsible: responsible
            ? responsible.agent.firstName + " " + responsible.agent.lastName
            : "",
          responsibleAssistant: responsibleAdjoint
            ? responsibleAdjoint.agent.firstName +
              " " +
              responsibleAdjoint.agent.lastName
            : "",
          actions: true,
        },
      });
    });

    // set the data with the columns and rows
    _setTableData({
      columns,
      rows,
    });
  };

  // EXPORT LIST
  const exportList = () => {
    if (exportListData) {
      let csv = "";
      const headings = {
        acronym: "Acronyme Service",
        name: "Désignation service DLM",
        responsible: "Responsable du service",
        responsibleAssistant: "Responsable adjoint du service",
      };

      // Loop the array of objects
      for (let row = 0; row < exportListData.length; row++) {
        const rowData = exportListData[row];
        const responsible = (rowData.responsibles || []).find(
          (r) => r.responsibleType === RESPONSIBLE_TYPE
        );
        const responsibleAdjoint = (rowData.responsibles || []).find(
          (r) => r.responsibleType === RESPONSIBLE_ASSISTANT_TYPE
        );

        const list = {
          acronym: rowData.acronym,
          name: rowData.name,
          responsible: responsible
            ? responsible.agent.firstName + " " + responsible.agent.lastName
            : "",
          responsibleAssistant: responsibleAdjoint
            ? responsibleAdjoint.agent.firstName +
              " " +
              responsibleAdjoint.agent.lastName
            : "",
        };
        let keysAmount = Object.keys(list).length;
        let keysCounter = 0;

        // If this is the first row, generate the headings
        if (row === 0) {
          const addHead = (key: string) => {
            // Do not add a semicolon on the end
            // The '\r\n' adds a new line
            csv += key + (keysCounter + 1 < keysAmount ? "," : "\r\n");
            keysCounter++;
          };

          // Loop each property of the object
          for (let key in list) {
            // rename and add the headings
            addHead((headings as any)[key]);
          }
        } else {
          // If this is not the first row
          // add the values
          for (let key in list) {
            const value = (list as any)[key];
            // surround the value with "" so that the inside commas are not taken into account
            csv +=
              '"' + value + '"' + (keysCounter + 1 < keysAmount ? "," : "\r\n");
            keysCounter++;
          }
        }

        keysCounter = 0;
      }

      // Once we are done looping, download the .csv by creating a link
      let link = document.createElement("a");
      link.id = "download-services-dlm-csv";
      link.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(csv)
      );
      link.setAttribute("download", "services-dlm.csv");
      document.body.appendChild(link);
      const downloadCsv = document.querySelector(
        "#download-services-dlm-csv"
      ) as HTMLElement;
      if (downloadCsv) {
        downloadCsv.click();
      }
    }
  };

  return (
    <Wrapper>
      <TableContainer>
        <TableComponent
          data={tableData}
          onExport={function () {}}
          rowActions={ROW_ACTIONS}
          onRowActionSelected={(infos: {
            row: IDivisionInfos;
            action: string;
          }) => handleRowActionSelected(infos)}
        />
      </TableContainer>
      {/* edit modal */}
      {isModalOpenEdit && editFormData && (
        <Modal onClose={() => _setIsModalOpenEdit(false)}>
          <EditDivisionForm
            title={
              editFormData.type === DIVISION.service.type
                ? "Modifier le service"
                : "Modifier la direction"
            }
            formData={editFormData}
            actionString="Modifier"
            onDivisionUpdated={() => {
              _setIsModalOpenEdit(false);
              loadData();
              _setToastMessage(
                editFormData.type === DIVISION.service.type
                  ? "Le service a été modifié"
                  : "La direction a été modifiée"
              );
            }}
          />
        </Modal>
      )}

      {/* responsible modal */}
      {isModalOpenResponsible && currentDivision && (
        <Modal onClose={() => _setIsModalOpenResponsible(false)}>
          <AddEditResponsibleForm
            title="Modifier les responsables du service"
            division={currentDivision}
            actionString="Ajouter"
            onResponsibleUpdated={() => {
              _setIsModalOpenResponsible(false);
              loadData();
            }}
          />
        </Modal>
      )}
      {/* TOAST */}
      {toastMessage && (
        <ToastAlert
          text={toastMessage}
          handleOk={() => {
            _setToastMessage("");
          }}
          endedTimer={() => {
            _setToastMessage("");
          }}
        />
      )}
    </Wrapper>
  );
};

export default ServicesTable;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TableOptions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const TableContainer = styled.div`
  flex-grow: 1;
  overflow: auto;
`;
